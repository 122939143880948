import React from "react";
import RemainingDaysProgressBar from "../../../components/RemainingDaysProgressBar/RemainingDaysProgressBar";
import "../../../styles/global.scss";
import "../styles/EmployeesPage.css";
import { useTheme } from "@mui/material";

const EmployeesPageHeader = () => {
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;

  return (
    <div
      className="flex-direction-column gap-24 width-100 employees-page-header"
      style={{ backgroundColor: backgroundDefault }}
    >
      <div className="flex-direction-row employees-header-content">
        <div className="flex-direction-row justify-center align-center gap-8 flex-1-0-0 ">
          <p className="Grotesk-500 font-32 employees-header-number">
            {statistic.Permanent + statistic.Temporary}
          </p>
          <p className="Grotesk-500 font-14 employees-header-text">
            Ukupno zaposlenih
          </p>
        </div>
        <div className="flex-direction-row justify-center align-center gap-8 flex-1-0-0">
          <p className="Grotesk-500 font-32 employees-header-number">
            {statistic.Permanent}
          </p>
          <p className="Grotesk-500 font-14 employees-header-text">
            Zaposleni na odredjeno
          </p>
        </div>
        <div className="flex-direction-row justify-center align-center gap-8 flex-1-0-0">
          <p className="Grotesk-500 font-32 employees-header-number">
            {statistic.Temporary}
          </p>
          <p className="Grotesk-500 font-14 employees-header-text">
            Zaposleni na neoredjeno
          </p>
        </div>
      </div>
      <RemainingDaysProgressBar
        total={statistic.Permanent + statistic.Temporary}
        left={statistic.Permanent}
        className="employees-header-progress-bar"
      />
    </div>
  );
};

export default EmployeesPageHeader;

const statistic = { Permanent: 60, Temporary: 40 };
