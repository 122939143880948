import React, { useState } from "react";
import "../styles/homepage.scss";
import ReactApexChart from "react-apexcharts";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";

const AvgGraph = ({ title, color }) => {
  const [activeButton, setActiveButton] = useState("Nedeljno");
  const options = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: "Dolazci",
        data:
          activeButton === "Nedeljno"
            ? data.Nedeljno
            : activeButton === "Mesečno"
            ? data.Mesečno
            : data.Godišnje,
      },
    ],
    colors: [color || "#B155D9"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 0,
            color: color || "#CB99E0",
            opacity: 0.9,
          },
          {
            offset: 100,
            color: color || "#CB99E0",
            opacity: 0.1,
          },
        ],
      },
    },
    xaxis: {
      categories: ["PON", "UTO", "SRE", "CET", "PET"],
    },
    yaxis: {
      opposite: true,
    },
  };

  const handleButtonClick = (title) => {
    setActiveButton(title);
  };

  return (
    <div className="daily-graph-container-wrapper">
      <div className="flex-direction-row gap-8 width-100 avg-graph-buttons">
        <ButtonApp
          title="Nedeljno"
          lightPurple
          active={activeButton === "Nedeljno"}
          className="avg-graph-btn"
          onButtonClick={() => handleButtonClick("Nedeljno")}
        />
        <ButtonApp
          title="Mesečno"
          lightPurple
          active={activeButton === "Mesečno"}
          className="avg-graph-btn"
          onButtonClick={() => handleButtonClick("Mesečno")}
        />
        <ButtonApp
          title="Godišnje"
          lightPurple
          active={activeButton === "Godišnje"}
          className="avg-graph-btn"
          onButtonClick={() => handleButtonClick("Godišnje")}
        />
      </div>
      <div className="daily-graph-container">
        <ReactApexChart
          options={options}
          series={options.series}
          type="area"
          height={400}
        />
      </div>
    </div>
  );
};

export default AvgGraph;

const data = {
  Nedeljno: [6, 7.5, 8, 6.4, 9],
  Mesečno: [2, 4.5, 6, 6.4, 9],
  Godišnje: [7, 7.5, 10, 6.4, 9],
};
