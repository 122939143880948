import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../store/userSlice";
import UserService from "../services/userService";

const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();
  const tokenRefreshTimeout = useRef(null); // Store the timeout reference

  // Save user data to Redux
  const handleSaveUser = () => {
    const userInfo = UserService.getTokenParsed();
    if (userInfo) {
      dispatch(setUser(userInfo));
    }
  };

  const scheduleTokenRefresh = () => {
    const calculateTimeUntilExpiration = () => {
      const token = UserService.getTokenParsed();
      if (!token || !token.exp) {
        console.error("Token is invalid or missing 'exp' field.");
        return null;
      }

      const expirationTimeMs = token.exp * 1000;
      const now = Date.now();
      const timeUntilExpiration = expirationTimeMs - now - 30000;

      if (timeUntilExpiration <= 0) {
        console.warn("Token has already expired or is about to expire.", token);
        return null;
      }

      console.log(`Time until expiration: ${timeUntilExpiration} ms`);
      return timeUntilExpiration;
    };

    const timeUntilExpiration = calculateTimeUntilExpiration();

    if (!timeUntilExpiration) {
      console.warn(
        "Token refresh scheduling aborted due to invalid or expired token."
      );
      return;
    }

    // Clear any existing timeout
    if (tokenRefreshTimeout.current) {
      clearTimeout(tokenRefreshTimeout.current);
    }

    // Schedule the token refresh
    tokenRefreshTimeout.current = setTimeout(async () => {
      await UserService.updateToken(() => {
        handleSaveUser();
        scheduleTokenRefresh();
      }).catch(() => {
        console.error("Token refresh failed or session expired.");
        UserService.doLogin(); // Redirect to login
      });
    }, timeUntilExpiration);
  };

  useEffect(() => {
    // Initialize Keycloak
    const initializeKeycloak = async () => {
      try {
        await UserService.initKeycloak((authenticated) => {
          if (authenticated) {
            handleSaveUser(); // Save user info on successful authentication
            scheduleTokenRefresh(); // Start token refresh scheduling
          }
          setIsAuthenticated(authenticated);
        });
      } catch (error) {
        console.error("Error initializing Keycloak:", error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeKeycloak();

    return () => {
      // Cleanup on unmount
      if (tokenRefreshTimeout.current) {
        clearTimeout(tokenRefreshTimeout.current);
      }
    };
  }, []);

  if (isLoading || !isAuthenticated) {
    return <div>Loading...</div>;
  }

  return children;
};

export default AuthProvider;
