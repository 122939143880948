import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setUser } from "./userSlice";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://dev.abouthr.rs", // Replace with your endpoint
  }),
  prepareHeaders: (headers) => {
    // Optional: Add headers if needed (e.g., for authentication)
    headers.set("Content-Type", "application/json");
    return headers;
  },
  tagTypes: ["User"],
  endpoints: (builder) => ({
    executeGraphQL: builder.query({
      query: ({ query, variables }) => ({
        url: "",
        method: "POST",
        body: { query, variables },
      }),
    }),
    getUsers: builder.query({
      query: () => ({
        url: "",
        method: "POST",
        body: {
          query: `
          query {
            allFilms {
              films {
                title
                director
                releaseDate
                speciesConnection {
                  species {
                    name
                    classification
                    homeworld {
                      name
                    }
                  }
                }
              }
            }
          }
        `,
        },
      }),
      providesTags: ["User"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // Make sure to dispatch the correct action for films
          dispatch(setUser(data));
        } catch (error) {
          console.error("Failed to fetch users:", error);
        }
      },
    }),
  }),
});

export const { useExecuteGraphQLQuery, useGetUsersQuery } = apiSlice;
