import React, { useState } from "react";
import "./styles/InputApp.scss";
import { useTheme } from "@mui/material";
import { icons } from "../../constants";

const InputApp = ({
  className,
  inputClassName,
  width,
  placeholder,
  firstIcon,
  firstIconReplacement,
  secondIcon,
  dropdown,
  optionsArray,
  file,
  textarea,
  height,
  label,
  value,
  onChange,
  type,
  error,
}) => {
  const [isInputFocus, setIsInputFocus] = useState(dropdown ? true : false);
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const defaultBackground = theme.palette.background.default;
  const neutralDarker = theme.palette.neutral.darker;
  const primaryLightest = theme.palette.primary.lightest;
  const neutralMedium = theme.palette.neutral.medium;

  const handleInputFocus = () => {
    setIsInputFocus(true);
    if (dropdown) {
      setIsOpen(true);
    }
  };

  const handleInputBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      if (!dropdown) {
        setIsInputFocus(false);
      }
      setIsOpen(false);
    }
  };

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
    if (!dropdown) {
      setIsInputFocus(false);
    }
  };

  return (
    <div className="flex-direction-column align-end gap-12 width-100 height-100 flex-1-0-0">
      {label && (
        <label
          className="Grotesk-500 font-16 weight-500 width-100"
          style={{
            color: neutralMedium,
            textAlign: "start",
            lineHeight: "20px",
          }}
        >
          {label}
        </label>
      )}
      <div
        className={`flex-direction-column align-center justify-center relative input-app ${className} ${
          isInputFocus ? "focus" : ""
        } ${error ? "error" : ""}`}
        style={{
          height: height ? height : "48px",
          backgroundColor: defaultBackground,
          width: width || "fit-content",
          borderRadius: isOpen ? "12px 12px 0 0 " : "12px",
        }}
        onBlur={handleInputBlur}
      >
        <div
          className="flex-direction-row align-center align-stretch gap-10"
          style={{ height: "100%" }}
        >
          {firstIcon && (
            <img
              src={isInputFocus ? firstIconReplacement : firstIcon}
              alt="icon"
              width={24}
              height={24}
            />
          )}

          {textarea ? (
            <textarea
              className={`Grotesk-400 font-16 `}
              onFocus={handleInputFocus}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          ) : (
            <input
              className={`${inputClassName} Grotesk-400 font-16 `}
              style={{
                backgroundColor: defaultBackground,
                color: neutralDarker,
                height: "100%",
                width: "100%",
              }}
              onFocus={handleInputFocus}
              placeholder={placeholder || ""}
              disabled={dropdown}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              type={type}
            />
          )}

          {(secondIcon || dropdown) && (
            <img
              src={dropdown ? icons.arrow_down : secondIcon}
              alt="icon"
              width={18}
              height={7}
              onClick={() => setIsOpen(!isOpen)}
              tabIndex={0}
              className={`${isOpen ? "arrow-open" : "arrow-closed"}`}
            />
          )}
        </div>

        {dropdown && (
          <div
            className={`absolute dropdown-options ${isOpen ? "open" : ""}`}
            style={{
              backgroundColor: defaultBackground,
              borderRadius: "0 0 12px 12px",
            }}
          >
            <ul style={{ borderRadius: isOpen ? "0 0 12px 12px" : "0" }}>
              {optionsArray.map((option, index) => (
                <li
                  key={index}
                  className={`Grotesk-400 font-16 ${
                    option === value ? "chosen" : ""
                  } ${index === optionsArray.length - 1 ? "last-li" : ""}`}
                  style={{
                    backgroundColor:
                      option === value ? primaryLightest : "transparent",
                    color: neutralDarker,
                  }}
                  onClick={() => handleOptionClick(option)}
                  tabIndex={0}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default InputApp;
