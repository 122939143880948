import React from "react";
import "../styles/EmployeesPage.css";
import "../../../styles/global.scss";
import { useTheme } from "@mui/material";
import InputApp from "../../../components/InputApp/InputApp";
import { icons } from "../../../constants";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";
import EmployeesRow from "./EmployeesRow";
import { useNavigate } from "react-router-dom";

const EmployeesTable = ({}) => {
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const navigate = useNavigate();

  const onButtonClick = () => {
    navigate("/employees/add");
  };
  return (
    <div
      className="flex-direction-column gap-24  align-end align-stretch employees-table"
      style={{ backgroundColor: backgroundDefault }}
    >
      <div className="flex-direction-row gap-12 align-stretch align-center employees-table-btn-search">
        <InputApp
          className="employees-table-search"
          placeholder="search"
          firstIcon={icons.search}
          firstIconReplacement={icons.searchPurple}
        />
        <div className="flex-direction-row gap-12 align-stretch align-center">
          <div style={{ width: "287px", height: "100%" }}></div>
          <ButtonApp
            title="Dodaj zaposlenog"
            className="employees-table-add-btn"
            purple
            onButtonClick={onButtonClick}
          />
        </div>
      </div>
      <div className="flex-direction-column gap-12 width-100 employees-table-content">
        {EmployeesArray.map((employee, index) => (
          <EmployeesRow key={index} employee={employee} />
        ))}
      </div>
    </div>
  );
};

export default EmployeesTable;

const EmployeesArray = [
  {
    name: "Marko Gvozdenović",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    position: "Frontend developer",
    jobSector: "Odeljak Beograd",
    status: "Određeno 6",
  },
  {
    name: "Milan Stanković Milan StankovićMilan Stanković Milan Stanković",
    email:
      "markogvozdenovic@gmail.commarkogvozdenovic@gmail.com markogvozdenovic@gmail.com",
    id: "22447564692244756 4692244756469 2244756469",
    position: "Frontend developer Frontend developer Frontend developer",
    jobSector: "Odeljak Beograd Odeljak BeogradOdeljak BeogradOdeljak Beograd",
    status: "Određeno 6 Određeno 6 Određeno 6 Određeno 6",
  },
  {
    name: "Milan Stanković",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    position: "Frontend developer",
    jobSector: "Odeljak Beograd",
    status: "Određeno 6",
  },
  {
    name: "Milan Stanković",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    position: "Frontend developer",
    jobSector: "Odeljak Beograd",
    status: "Određeno 6",
  },
  {
    name: "Milan Stanković",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    position: "Frontend developer",
    jobSector: "Odeljak Beograd",
    status: "Određeno 6",
  },
  {
    name: "Milan Stanković",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    position: "Frontend developer",
    jobSector: "Odeljak Beograd",
    status: "Određeno 6",
  },
  {
    name: "Milan Stanković",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    position: "Frontend developer",
    jobSector: "Odeljak Beograd",
    status: "Određeno 6",
  },
  {
    name: "Milan Stanković",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    position: "Frontend developer",
    jobSector: "Odeljak Beograd",
    status: "Određeno 6",
  },
  {
    name: "Milan Stanković",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    position: "Frontend developer",
    jobSector: "Odeljak Beograd",
    status: "Određeno 6",
  },
];
