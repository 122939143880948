import React from "react";
import "../../../styles/attendancePage.scss";
import "../../../../../styles/global.scss";
import CumulativeViewRow from "./CumulativeViewRow";

const CumulativeView = () => {
  return (
    <div className="flex-direction-column gap-24 width-100 justify-center align-start cumulative-view">
      <div className="flex-direction-row gap-96 width-100 justify-space-between align-start cumulative-view-header">
        <p className="Grotesk-500 font-14 cumulative-view-header-employee">
          Zaposleni
        </p>
        <div className="flex-direction-row flex-1-0-0 gap-4 align-start">
          {header.map((item, index) => (
            <p
              className="Grotesk-500 font-14 flex-1-0-0 cumulative-view-header-item"
              key={index}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
      <div className="flex-direction-column gap-24 width-100 align-start cumulative-view-content">
        {" "}
        {data.map((item, index) => (
          <CumulativeViewRow key={index} data={item} />
        ))}
      </div>
    </div>
  );
};

export default CumulativeView;

const header = [
  "ID",
  "Upisano vreme",
  "Željeno vreme",
  "Razlika",
  "Plaćeno",
  "Radni dani",
];

const data = [
  {
    img: "",
    name: "Marko Gvozdenović",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    total_time: "157:30",
    desired_time: "168:00",
    difference: "-10:30",
    paid: "157:00",
    working_day: "20",
  },
  {
    img: "",
    name: "Marko Gvozdenović",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    total_time: "157:30",
    desired_time: "168:00",
    difference: "+10:30",
    paid: "157:00",
    working_day: "20",
  },
  {
    img: "",
    name: "Marko Gvozdenović",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    total_time: "157:30",
    desired_time: "168:00",
    difference: "-10:30",
    paid: "157:00",
    working_day: "20",
  },
  {
    img: "",
    name: "Marko Gvozdenović",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    total_time: "157:30",
    desired_time: "168:00",
    difference: "+10:30",
    paid: "157:00",
    working_day: "20",
  },
  {
    img: "",
    name: "Marko Gvozdenović",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    total_time: "157:30",
    desired_time: "168:00",
    difference: "+10:30",
    paid: "157:00",
    working_day: "20",
  },
  {
    img: "",
    name: "Marko Gvozdenović",
    email: "markogvozdenovic@gmail.com",
    id: "2244756469",
    total_time: "157:30",
    desired_time: "168:00",
    difference: "+10:30",
    paid: "157:00",
    working_day: "20",
  },
];
