import React, { useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import "./styles/navbar.scss";
import { icons, images } from "../../constants/index.js";
import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import "../../styles/global.scss";
import { clearUser } from "../../store/userSlice.js";
import UserService from "../../services/userService.js";

export const CustomLink = ({
  to,
  presence,
  children,
  src,
  display,
  presenceReview,
  ...props
}) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  const theme = useTheme();
  const littleLighter = theme.palette.primary.littleLighter;
  const neutralMain = theme.palette.neutral.main;

  return (
    <Link
      to={to}
      {...props}
      style={{
        display: display ? display : "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "8px",
        height: "48px",
        padding: "14px",
        gap: "8px",
        backgroundColor: isActive ? littleLighter : "",
        "--hover-background-color": littleLighter || "",
      }}
      // className={isActive ? "active" : ""}
    >
      {!presenceReview ? (
        <img src={src} height="24px" width="24px" />
      ) : (
        <div style={{ width: "24px", height: "24px" }}></div>
      )}

      <div className="flex-direction-column width-100 gap-12">
        <p
          className="Grotesk-500 font-16"
          style={{ color: neutralMain, width: "100%" }}
        >
          {children}
        </p>
      </div>
    </Link>
  );
};

const user = {
  id: 12264478772,
  name: "Marko Markovic",
};
const Navbar = () => {
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const [openPresence, setOpenPresence] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    UserService.doLogout();
    dispatch(clearUser());
  };

  return (
    <div className="navbar" style={{ backgroundColor: backgroundDefault }}>
      <div className="navbar-avatar">
        <div className="navbar-avatar-items">
          <img
            src={images.profilePicture}
            className="navbar-avatar-img"
            height="48px"
            width="48px"
          />
          <div className="navbar-avatar-text">
            <p>{user.name}</p>
            <p
              style={{
                color: "#7A7A7A",
              }}
            >
              ID {user.id}
            </p>
          </div>
        </div>
      </div>
      <div className="navbar-main">
        <div className="navbar-menu">
          <div className="navbar-menu-items" style={{ width: "100%" }}>
            <ul>
              <CustomLink to="/" src={icons.home}>
                Početna
              </CustomLink>
              <CustomLink to="/profile" src={icons.profile}>
                Profil
              </CustomLink>
              <div className="flex-direction-row align-center justify-space-between width-100 relative">
                <CustomLink to="/attendance" src={icons.time}>
                  Prisustvo
                </CustomLink>
                <img
                  src={!openPresence ? icons.arrow_down : icons.arrow_up}
                  width={18}
                  height={7}
                  onClick={() => {
                    setOpenPresence(!openPresence);
                  }}
                  className="pointer presence-arrow"
                />
              </div>
              <CustomLink
                to="/presence"
                display={!openPresence ? "none" : "flex"}
                presenceReview
              >
                Pregled prisustva
              </CustomLink>
              <CustomLink to="/employees" src={icons.employees}>
                Zaposleni
              </CustomLink>
              <CustomLink to="/requests" src={icons.requests}>
                Zahtevi
              </CustomLink>
              <CustomLink to="/notifications" src={icons.notifications}>
                Obaveštenja
              </CustomLink>
              <CustomLink to="/settings" src={icons.settings}>
                Podešavanja
              </CustomLink>
            </ul>
          </div>
        </div>
        <div
          className="navbar-menu-items"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CustomLink to="/login" src={icons.log_out} onClick={handleLogout}>
            Log out
          </CustomLink>
          <CustomLink to="/help" src={icons.help}>
            Pomoć
          </CustomLink>
          <CustomLink to="/building" src={icons.building}>
            <div className="flex-direction-row align-center justify-space-between width-100">
              Midmeding Nova
              <img src={icons.arrow_right} width={18} height={16} />
            </div>
          </CustomLink>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
