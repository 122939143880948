import React from "react";
import "../styles/EmployeesPage.css";
import "../../../styles/global.scss";
import { images, icons } from "../../../constants";
import { useTheme } from "@mui/material";

const EmployeesRow = ({ employee }) => {
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;
  const neutralLighterMedium = theme.palette.neutral.light;

  return (
    <div className="flex-direction-row gap-48 employees-row">
      <div className="flex-direction-row gap-8 align-center employees-row-picture-name">
        <img src={images.avatar} alt="slika" />
        <div className="flex-direction-column gap-2 justify-center align-start">
          <p className="Grotesk-400 font-14" style={{ color: neutralMedium }}>
            {employee.name}
          </p>
          <p
            className="Grotesk-400 font-14"
            style={{ color: neutralLighterMedium }}
          >
            {employee.email}
          </p>
        </div>
      </div>
      <div className="flex-direction-row gap-48 employees-row-data">
        <div className="flex-direction-row align-center employees-row-value">
          <p className="Grotesk-400 font-14" style={{ color: neutralMedium }}>
            {employee.id}
          </p>
        </div>
        <div className="flex-direction-row align-center employees-row-value">
          <p className="Grotesk-400 font-14" style={{ color: neutralMedium }}>
            {employee.position}
          </p>
        </div>
        <div className="flex-direction-row align-center employees-row-value">
          <p className="Grotesk-400 font-14" style={{ color: neutralMedium }}>
            {employee.jobSector}
          </p>
        </div>
        <div className="flex-direction-row align-center employees-row-value">
          <p className="Grotesk-400 font-14" style={{ color: neutralMedium }}>
            {employee.status}
          </p>
        </div>
        <div className="flex-direction-row align-center pointer">
          <img src={icons.more} width={24} height={24} />
        </div>
      </div>
    </div>
  );
};

export default EmployeesRow;
