import React, { useState } from "react";
import "./styles/homepage.scss";
import TimeButton from "./components/TimeButton";
import TimeTable from "./components/TimeTable";
import HeaderTimeLine from "./components/HeaderTimeLine";
import DailyGraph from "./components/DailyGraph";
import AvgGraph from "./components/AvgGraph";
import Notifications from "./components/Notifications";
import { useTheme } from "@mui/material";

const HomePage = () => {
  const [rowId, setRowId] = useState();
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const neutralMedium = theme.palette.neutral.medium;
  return (
    <div className="homepage-main">
      <div className="div-white-r24-shadows">
        <HeaderTimeLine />
      </div>
      <div className="time-components">
        <div
          className="time-buttons div-white-r24-shadows"
          style={{ backgroundColor: backgroundDefault }}
        >
          <TimeButton title="Dolazak" color="#E2CEEA" />
          <TimeButton title="Odlazak" color="#CEEACF" />
          <TimeButton title="Pauza" color="#E0E0E0" />
          <TimeButton title="Rad od kuce" color="#FFFA8F" />
          <TimeButton title="Privatan izlazak" color="#F5E4C3" />
          <TimeButton title="Sluzbeni izlazak" color="#CEDBEB" />
        </div>
        <div
          className="time-table div-white-r24-shadows"
          style={{ backgroundColor: backgroundDefault }}
        >
          <TimeTable
            onRowClick={(value) => setRowId(value)}
            clickedRow={rowId}
            list={list}
          />
        </div>
      </div>
      <div
        className="div-white-r24-shadows daily-graph"
        style={{ backgroundColor: backgroundDefault, color: neutralMedium }}
      >
        <div className="daily-graph-header">
          <p className="Grotesk-500 font-16">Dnevna vremenska osa</p>
          <div className="daily-graph-header-legend">
            <div
              className="daily-graph-header-legend"
              style={{
                gap: "6px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#883CEF",
                }}
              ></div>
              <p className="Grotesk-400 font-16">vreme rada</p>
            </div>
            <div
              className="daily-graph-header-legend"
              style={{
                gap: "6px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#5707B1",
                }}
              ></div>
              <p className="Grotesk-400 font-16">vreme pauze</p>
            </div>
          </div>
        </div>
        <DailyGraph />
      </div>
      <div className="time-components">
        <div
          className="avg-graph div-white-r24-shadows"
          style={{ backgroundColor: backgroundDefault }}
        >
          <div className="avg-graph-header">
            <p className="Grotesk-500 font-16">Prosečno vreme dolaska</p>
            <p className="p-16-black-700" style={{ fontSize: "32px" }}>
              7:32
            </p>
          </div>

          <AvgGraph />
        </div>
        <Notifications />
      </div>
    </div>
  );
};
export default HomePage;
const list = [
  {
    _id: "111",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
  },
  {
    _id: "222",
    type: "Odlazak",
    time: "11:05",
    address: "112, Vracar Vracar Vracar Vracar VracarVracarVracarVracar",
  },
  {
    _id: "333",
    type: "Pauza",
    time: "11:05",
    address: "112, Vracar",
  },
  {
    _id: "444",
    type: "Rad od kuce Rad od kuce Rad od kuce Rad od kuce",
    time: "11:05",
    address: "112, Vracar",
  },
  {
    _id: "555",
    type: "Privatan izlazak",
    time: "11:05",
    address: "112, Vracar",
  },
  {
    _id: "666",
    type: "Sluzbeni izlazak",
    time: "11:05",
    address: "112, Vracar",
  },
  {
    _id: "777",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
  },
  {
    _id: "888",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
  },
  {
    _id: "999",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
  },
  {
    _id: "1111",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
  },
  {
    _id: "2222",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
  },
  {
    _id: "3333",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
  },
];
