import React, { useState } from "react";
import "../../../styles/attendancePage.scss";
import "../../../../../styles/global.scss";
import ButtonApp from "../../../../../components/ButtonsApp/ButtonRegular";
import Dropdown from "../../../../../components/Dropdown/Dropdown";
import InputApp from "../../../../../components/InputApp/InputApp";
import CalendarInput from "../../../../../components/Calendar/Calendar";

const AddActionModal = ({ handleCloseClick, data, editing, id }) => {
  const [actionObject, setActionObject] = useState(
    data
      ? data
      : {
          type: "",
          time: "",
          date: "",
          comment: "",
        }
  );

  const setInputValue = (key, value) => {
    setActionObject((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };
  const [errors, setErrors] = useState({});

  const validateAllFields = () => {
    let hasError = false;

    setErrors({});

    Object.entries(actionObject).forEach(([key, value]) => {
      let err = false;

      if (!value && key !== "comment") {
        err = true;
      }

      if (err) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: true,
        }));
        hasError = true;
      }
    });

    return !hasError;
  };

  const onSaveClick = () => {
    const isValid = validateAllFields();

    console.log(errors);

    if (isValid) {
      // ako je editing imas u propsu id za koji se menjaju podaci
      if (editing) {
        console.log("editujemo", actionObject);
      } else {
        console.log("dodajemo", actionObject);
      }
    }
  };

  return (
    <div className="flex-direction-column gap-24 align-start align-stretch add-action-modal">
      <div className="flex-direction-column gap-8 justify-space-between height-100 align-start align-stretch add-action-modal-content">
        <Dropdown
          options={options}
          label="Tip akcije"
          setSelectedOption={(value) => setInputValue("type", value)}
          selectedOption={actionObject.type}
          error={errors.type}
        />
        <InputApp
          label="Vreme"
          placeholder="12:22"
          className="add-action-modal-time"
          onChange={(value) => setInputValue("time", value)}
          value={actionObject.time}
          type="time"
          error={errors.time}
        />
        {/* <InputApp
          label="Datum"
          className="add-action-modal-time"
          onChange={(value) => setInputValue("date", value)}
          value={actionObject.date}
          type="date"
          error={errors.date}
        /> */}
        <CalendarInput
          onDateChange={(value) => setInputValue("date", value)}
          value={actionObject.date}
          error={errors.date}
          label="Datum"
        />
        <InputApp
          label="Komentar"
          className="add-action-modal-textarea"
          onChange={(value) => setInputValue("comment", value)}
          value={actionObject.comment}
          error={errors.coment}
          textarea
        />
      </div>
      <div className="flex-direction-row gap-10 align-center width-100 modal-buttons">
        <ButtonApp
          title="Otkazi"
          width="100%"
          onButtonClick={() => handleCloseClick()}
        />
        <ButtonApp
          purple
          title="Sacuvaj"
          width="100%"
          onButtonClick={onSaveClick}
        />
      </div>
    </div>
  );
};

export default AddActionModal;

const options = [
  "Dolazak",
  "Odlazak",
  "Privatni Izlazak",
  "Pauza",
  "Rad od kuce",
];
