import React, { useState } from "react";
import "./styles/AddEmployeePage.css";
import "../../styles/global.scss";
import { useTheme } from "@mui/material";
import ButtonApp from "../../components/ButtonsApp/ButtonRegular";
import InputApp from "../../components/InputApp/InputApp";
import { icons } from "../../constants";
import Dropdown from "../../components/Dropdown/Dropdown";
import { useNavigate } from "react-router-dom";
import ImageDropzone from "../../components/UploadCropImage/components/ImageDropzone";
import PhoneNumberInput from "./components/PhoneNumberInput";
import { validateString } from "../../utils/validateString";

const AddEmployeePage = () => {
  const [image, setImage] = useState();
  const [employeeObject, setEmployeeObject] = useState({
    name: "",
    gender: "",
    birth_date: "",
    email: "",
    address: "",
    JMBG: "",
    identity_card: "",
    ID: "",
    job_position: "",
    work_sector: "",
    work_location: "",
    countryCode: "",
    phone: "",
  });
  const [errors, setErrors] = useState({});

  const setInputValue = (key, value) => {
    setEmployeeObject((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const validateAllFields = () => {
    let hasError = false;

    setErrors({});

    Object.entries(employeeObject).forEach(([key, value]) => {
      let err = false;

      if (!value) {
        err = true;
      } else {
        switch (key) {
          case "email":
            err = !validateString(value, "e_mail");
            break;
          case "JMBG":
          case "identity_card":
          case "ID":
            err = !validateString(value, "number");
            break;
          default:
            err = value.trim() === "";
            break;
        }
      }

      if (err) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: true,
        }));
        hasError = true;
      }
    });

    return !hasError;
  };
  const theme = useTheme();
  const defaultBackground = theme.palette.background.default;
  const neutralMedium = theme.palette.neutral.medium;
  const neutralMain = theme.palette.neutral.main;

  const navigate = useNavigate();

  const onCancelClick = () => {
    navigate("/employees");
  };

  const onSaveClick = () => {
    const isValid = validateAllFields();

    console.log(errors);

    if (isValid) {
      console.log(employeeObject);
    }
  };

  return (
    <div className="flex-direction-column gap-24 width-100 add-employee-page">
      <div
        className="flex-direction-row gap-12 justify-center align-center align-stretch width-100 add-employee-search-buttons"
        style={{ backgroundColor: defaultBackground }}
      >
        <InputApp
          className="add-employee-search"
          placeholder="search"
          firstIcon={icons.search}
          firstIconReplacement={icons.searchPurple}
        />
        <ButtonApp
          title="Radna jedinica"
          className="add-employee-workplace-unit"
          textColor={neutralMedium}
          iconFirst={icons.building}
          padding="14px"
        />
      </div>
      <div
        className="flex-direction-column gap-24 align-center align-stretch width-100 add-employee-form"
        style={{ backgroundColor: defaultBackground }}
      >
        <div className="flex-direction-column gap-24 align-start align-stretch">
          <p
            className="Grotesk-500 font-16 weight-500"
            style={{ color: neutralMedium }}
          >
            Formular za dodavanje novog zaposlenog
          </p>
          <div className="flex-direction-column gap-12 align-start align-stretch">
            <ImageDropzone onImageDrop={(file) => setImage(file)} />
            <p
              className="Grotesk-500 font-16 weight-400"
              style={{ color: neutralMain }}
            >
              Fotografija
            </p>
          </div>
          <div className="flex-direction-row gap-24 width-100 add-employee-form-content">
            <div className="flex-direction-column gap-24 flex-1-0-0 add-employee-form-content-column">
              <InputApp
                label="Ime i Prezime"
                className="add-employee-form-input-wrapper"
                placeholder="Marko Zdravković"
                onChange={(value) => setInputValue("name", value)}
                value={employeeObject.name}
                error={errors.name}
              />
              <Dropdown
                options={options2}
                label="Pol"
                setSelectedOption={(value) => setInputValue("gender", value)}
                selectedOption={employeeObject.gender}
                error={errors.gender}
              />
              <InputApp
                label="Datum Rodjenja"
                className="add-employee-form-input-wrapper"
                placeholder="23.03.1987."
                onChange={(value) => setInputValue("birth_date", value)}
                value={employeeObject.birth_date}
                type="date"
                error={errors.birth_date}
              />
              <PhoneNumberInput
                className="add-employee-form-input-wrapper"
                setPhone={(value) => setInputValue("phone", value)}
                setCode={(value) => setInputValue("countryCode", value)}
                error={errors.phone}
              />
              <InputApp
                label="Email"
                className="add-employee-form-input-wrapper"
                placeholder="markomarkovic@gmail.com"
                onChange={(value) => setInputValue("email", value)}
                value={employeeObject.email}
                error={errors.email}
              />
              <InputApp
                label="Adresa"
                className="add-employee-form-input-wrapper"
                placeholder="Narodnih heroja 91"
                onChange={(value) => setInputValue("address", value)}
                value={employeeObject.address}
                error={errors.address}
              />
            </div>
            <div className="flex-direction-column gap-24 flex-1-0-0 add-employee-form-content-column">
              <InputApp
                label="JMBG"
                className="add-employee-form-input-wrapper"
                placeholder="1238281923891"
                onChange={(value) => setInputValue("JMBG", value)}
                value={employeeObject.JMBG}
                error={errors.JMBG}
              />
              <InputApp
                label="Broj licne karte"
                className="add-employee-form-input-wrapper"
                placeholder="45678"
                onChange={(value) => setInputValue("identity_card", value)}
                value={employeeObject.identity_card}
                error={errors.identity_card}
              />
              <Dropdown
                options={options1}
                label="Sektor rada"
                setSelectedOption={(value) =>
                  setInputValue("work_sector", value)
                }
                selectedOption={employeeObject.work_sector}
                error={errors.work_sector}
              />
              <Dropdown
                options={options1}
                label="Lokacija rada"
                setSelectedOption={(value) =>
                  setInputValue("work_location", value)
                }
                selectedOption={employeeObject.work_location}
                error={errors.work_location}
              />
              <InputApp
                label="ID"
                className="add-employee-form-input-wrapper"
                placeholder="1231234123"
                onChange={(value) => setInputValue("ID", value)}
                value={employeeObject.ID}
                error={errors.ID}
              />
              <InputApp
                label="Radna Pozicija"
                className="add-employee-form-input-wrapper"
                placeholder="HR konsultant"
                onChange={(value) => setInputValue("job_position", value)}
                value={employeeObject.job_position}
                error={errors.job_position}
              />
            </div>
          </div>
        </div>
        <div className="flex-direction-row gap-12 align-center add-employee-buttons">
          <ButtonApp
            title="Otkaži"
            width="218.5px"
            onButtonClick={onCancelClick}
          />
          <ButtonApp
            title="Sačuvaj"
            purple
            width="218.5px"
            onButtonClick={onSaveClick}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEmployeePage;

const options1 = [
  "Opcija 1",
  "Opcija 2",
  "Opcija 3",
  "Opcija 4",
  "Opcija 5",
  "Opcija 1",
  "Opcija 2",
  "Opcija 3",
  "Opcija 4",
  "Opcija 5",
];
const options2 = ["Muski", "Zenski"];
