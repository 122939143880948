import React, { useState } from "react";
import "../styles/homepage.scss";
import { icons, images } from "../../../constants";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";
import ModalApp from "../../../components/ModalApp/ModalApp";
import Notification from "./Notificaion";
import { useTheme } from "@mui/material";

const notifications = [
  {
    title: "Kolektivnii odmor",
    date: "12.April, 11:00 AM",
    text: "Drage kolege, molimo vas da da popunite zahtev koji se odnos",
    image: images.profilePicture,
  },
  {
    title: "Kolektivni odmor Kolektivni odmor",
    date: "10.April, 11:00 AM 12.April, 11:00 AM",
    text: "Drage kolege, molimo vas da popunite zahtev koji se odnos Drage kolege, molimo vas da popunite zahtev koji se odnosDrage kolege, molimo vas da popunite zahtev koji se odnosDrage kolege, molimo vas da popunite zahtev koji se odnos",
    image: images.profilePicture,
  },
  {
    title: "Kolektivni odmor Kolektivni odmor",
    date: "10.April, 11:00 AM 12.April, 11:00 AM",
    text: "Drage kolege, molimo vas da popunite zahtev koji se odnos Drage kolege, molimo vas da popunite zahtev koji se odnosDrage kolege, molimo vas da popunite zahtev koji se odnosDrage kolege, molimo vas da popunite zahtev koji se odnos",
    image: images.profilePicture,
  },
];

const Notifications = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openSections, setOpenSections] = useState({
    notifications: false,
    birthdays: false,
    meetings: false,
  });
  const toggleSection = (section) => {
    setOpenSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const neutralMedium = theme.palette.neutral.medium;
  return (
    <div
      className="notifications-container flex-direction-column gap-24 div-white-r24-shadows"
      style={{ backgroundColor: backgroundDefault }}
    >
      <div className="flex-direction-column width-100 gap-12 notification-content">
        <div className="flex-direction-column gap-12 notifications-content">
          <div className="notifications-header">
            <div className="notifications-header-row">
              <div
                className="notifications-img"
                style={{
                  backgroundColor: "#D95555",
                }}
              >
                <img src={icons.notifications} height="20px" width="20px" />
              </div>
              <p className="p-16-black-700" style={{ color: neutralMedium }}>
                Notifications
              </p>
            </div>
            <img
              src={icons.arrow_down}
              width={18}
              height={7}
              className={`${
                openSections.notifications ? "arrow-down" : "arrow-up"
              } pointer`}
              onClick={() => toggleSection("notifications")}
            />
          </div>
          {openSections.notifications &&
            notifications.map((notification, index) => (
              <Notification
                key={index}
                image={notification.image}
                text={notification.text}
                title={notification.title}
                date={notification.date}
              />
            ))}
        </div>
        <div className="birthdays-content">
          <div className="notifications-header">
            <div className="notifications-header-row">
              <div
                className="notifications-img"
                style={{
                  backgroundColor: "#E9853D",
                }}
              >
                <img src={icons.birthdays} height="20px" width="20px" />
              </div>
              <p className="p-16-black-700" style={{ color: neutralMedium }}>
                Birthdays
              </p>
            </div>
            <img
              src={icons.arrow_down}
              width={18}
              height={7}
              className={`${
                openSections.birthdays ? "arrow-down" : "arrow-up"
              } pointer`}
              onClick={() => toggleSection("birthdays")}
            />
          </div>
          {openSections.birthdays &&
            notifications.map((notification, index) => (
              <Notification
                key={index}
                image={notification.image}
                text={notification.text}
                title={notification.title}
                date={notification.date}
              />
            ))}
        </div>
        <div className="birthdays-content">
          <div className="notifications-header">
            <div className="notifications-header-row">
              <div
                className="notifications-img"
                style={{
                  backgroundColor: "#B155D9",
                }}
              >
                <img src={icons.meeting} height="20px" width="20px" />
              </div>
              <p className="p-16-black-700" style={{ color: neutralMedium }}>
                Meetings
              </p>
            </div>
            <img
              src={icons.arrow_down}
              width={18}
              height={7}
              className={`${
                openSections.meetings ? "arrow-down" : "arrow-up"
              } pointer`}
              onClick={() => toggleSection("meetings")}
            />
          </div>
          {openSections.meetings &&
            notifications.map((notification, index) => (
              <Notification
                key={index}
                image={notification.image}
                text={notification.text}
                title={notification.title}
                date={notification.date}
              />
            ))}
        </div>
      </div>
      <ButtonApp
        title="Dodaj objavu"
        icon={icons.add}
        width="100%"
        className="notifications-btn"
        onButtonClick={() => setOpenModal(true)}
      />
      {openModal && (
        <div className="flex-direction-row align-center justify-center add-notification">
          <ModalApp
            title="Dodaj objavu"
            handleCloseClick={() => setOpenModal(false)}
          />
        </div>
      )}
    </div>
  );
};
export default Notifications;
