import React, { useState } from "react";
import { images } from "../../../constants";

const PersonalInformation = () => {
  const Profile = {
    Id: "432-234-546-678 432-234-546-678 432-234-546-678 432-234-546-678 ",
    Adresa: "Milana Stojaka 88",
    Datum_Rodjenja: "11.07.1992",
    Telefon: "+381-64-822-1554",
    Ime_Kompanije: "Midmeding Nova",
    Lokacija: "Milosev Kladenac bb",
    Sektor: "Lokal IV",
    Smena: "Prva(07:00-15:00)",
  };

  const profileEntries = Object.entries(Profile);
  const firstHalf = profileEntries.slice(0, 4);
  const secondHalf = profileEntries.slice(4);

  const [image, setImage] = useState(null);

  return (
    <div className="flex-direction-row align-start gap-49 shadows height-100 personal-information">
      <div className="flex-direction-column gap-24 align-start align-stretch personal-information-picture-name">
        <div className="profile-picture">
          <img
            src={image ? URL.createObjectURL(image) : images.profilePicture}
            alt="Profile"
            className="profile-page-personal-information-uploaded-picture"
            width={130}
            height={130}
          />
        </div>
        <div className="flex-direction-column gap-24 align-start align-stretch personal-information-email-name">
          <div className="flex-direction-column align-stretch align-start gap-8">
            <p className="Grotesk-500 white-text font-20 white-text personal-information-name">
              Milan Stanojević
            </p>
            <p
              className="Grotesk-400 gray-3 font-16 capitalize"
              style={{ color: "#fff" }}
            >
              HR Team Lead
            </p>
          </div>
          <div className="flex-direction-column align-stretch align-start gap-5">
            <p
              className="Grotesk-400 gray-3 font-16 capitalize"
              style={{ color: "#fff" }}
            >
              Email
            </p>
            <p className="Grotesk-500 white-text font-16 white-text">
              midmeding@mail.com
            </p>
          </div>
        </div>
      </div>
      <div className="flex-direction-row flex-start gap-15 align-stretch personal-information-container">
        <div
          className="flex-direction-column gap-24 align-start column"
          style={{ width: "167px" }}
        >
          {firstHalf.map(([key, value]) => (
            <div key={key} className="flex-direction-column gap-5 align-start">
              <p
                className="Grotesk-400 gray-3 font-16  capitalize white-text"
                style={{ color: "#fff" }}
              >
                {key}
              </p>
              <p className="Grotesk-500 white-text font-16  white-text">
                {value}
              </p>
            </div>
          ))}
        </div>
        <div className="line"></div>
        <div
          className="flex-direction-column gap-24 align-start column"
          style={{ width: "195px" }}
        >
          {secondHalf.map(([key, value]) => (
            <div key={key} className="flex-direction-column gap-5 align-start">
              <p
                className="Grotesk-400 gray-3 font-16 "
                style={{ color: "#fff" }}
              >
                {key}
              </p>
              <p className="Grotesk-500 white-text font-16  white-text">
                {value}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
