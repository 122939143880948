import React from "react";
import InputApp from "../../../../../components/InputApp/InputApp";
import { icons } from "../../../../../constants";
import ButtonApp from "../../../../../components/ButtonsApp/ButtonRegular";
import { useTheme } from "@mui/material";
import CalendarInput from "../../../../../components/Calendar/Calendar";

const FilterPanel = ({ setDate, date }) => {
  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;
  return (
    <div className="flex-direction-row justify-center align-center gap-12 align-stretch filter-panel">
      <div className="flex-direction-row justify-center align-center gap-12 align-stretch">
        <InputApp
          className="attendance-page-search"
          placeholder="search"
          firstIcon={icons.search}
          firstIconReplacement={icons.searchPurple}
        />
        <ButtonApp
          title="Radna jedinica"
          className="workplace-unit"
          textColor={neutralMedium}
          iconFirst={icons.building}
          padding="14px"
        />
      </div>

      <div className="flex-direction-row justify-center align-center gap-12 align-stretch">
        {/* <InputApp
          className="add-action-modal-time"
          onChange={(value) => setDate(value)}
          value={date}
          type="date"
        /> */}
        <CalendarInput onDateChange={(value) => setDate(value)} value={date} />
        <ButtonApp
          title="Dodaj"
          purple
          className="filter-panel-add-btn"
          height="52px"
        />
      </div>
    </div>
  );
};

export default FilterPanel;
