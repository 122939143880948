import React, { useState } from "react";
import "../../styles/global.scss";
import "./styles/buttonApp.scss";
import { useTheme } from "@mui/material";
import { icons } from "../../constants";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";

const ButtonApp = ({
  title,
  padding,
  width,
  height,
  purple,
  disabled,
  lightPurple,
  active,
  icon,
  className,
  onButtonClick,
  textColor,
  backgroundColor,
  iconFirst,
}) => {
  const theme = useTheme();
  const littleLighter = theme.palette.primary.littleLighter;
  const darkNeutral = theme.palette.neutral.dark;

  const handleButtonClick = () => {
    onButtonClick();
  };

  return (
    <div
      className={`flex-direction-row justify-center align-center pointer gap-10 button-app ${
        purple ? "purple" : lightPurple ? "light-purple" : "transparent"
      } ${disabled ? "disabled" : ""} ${active ? "active" : ""} ${
        className ? className : ""
      }`}
      style={{
        padding: padding ? padding : "16px",
        width: width ? width : "168px",
        backgroundColor: lightPurple
          ? littleLighter
          : backgroundColor
          ? backgroundColor
          : "",
        height: height ? height : "none",
      }}
      onClick={handleButtonClick}
    >
      {iconFirst && <img src={iconFirst} alt="icon" width={20} height={20} />}
      <p
        style={{
          margin: "0",
          color: lightPurple ? darkNeutral : textColor ? textColor : "",
          lineHeight: "16px",
        }}
        className="Grotesk-500 font-16"
      >
        {title}
      </p>
      {icon && <img src={icon} alt="icon" width={20} height={20} />}
    </div>
  );
};

export default ButtonApp;
