import React, { useState, useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import "chart.js/auto";
import { useTheme } from "@mui/material";
import "./styles/dailyGraph.scss";
import { Margin } from "@mui/icons-material";

const timeLines = {
  dolazak: "07:32",
  odlazak: "15:34",
  total: "08:02",
};

const DailyGraph = ({ title, color, item, type, homePage, status }) => {
  const activities = item;

  const startTick =
    status !== "unregistered" && status !== "holiday"
      ? Math.floor(item[0].startTime) - 1
      : Math.floor(item[0].startTime);
  const endTick =
    status !== "unregistered" && status !== "holiday"
      ? Math.floor(item[item.length - 1].endTime) + 1
      : Math.floor(item[item.length - 1].endTime);

  const scrollableRef = useRef(null);

  const typeColors = {
    work:
      status === "unregistered"
        ? "#FFEEED"
        : status === "holiday"
        ? "#DFFAE0"
        : "#883CEF",
    break:
      status === "unregistered"
        ? "#FFEEED"
        : status === "holiday"
        ? "#DFFAE0"
        : "#5707B1",
  };

  // Convert activities to ApexCharts series data
  const seriesData = activities.map((activity) => ({
    x: activity.name,
    y: [activity.startTime, activity.endTime],
    fillColor: typeColors[activity.type],
    height: 40,
  }));

  // Use theme
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const backgroundAlt = theme.palette.background.alt;
  const lightest = theme.palette.primary.lightest;

  // ApexCharts Configuration
  const options = {
    series: [
      {
        data: seriesData,
      },
    ],
    chart: {
      type: "rangeBar",
      background: backgroundAlt,
      height: 56, // Total height of the chart
      events: {
        click: function (event, chartContext, config) {
          return false;
        },
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: 20, // Set bar height to a percentage or a specific value
        borderRadius: 8,
        rangeBarGroupRows: true,
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "numeric",
      tickAmount: endTick - startTick, // Change tick amount based on screen size
      labels: {
        style: {
          fontFamily: "Outfit",
        },
        formatter: (val) => `${val}`,
      },
      min: startTick,
      max: endTick,
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      row: {
        colors: [
          status === "unregistered"
            ? "#FFEEED"
            : status === "checkOut"
            ? "#FEFDD4"
            : status === "holiday"
            ? "#DFFAE0"
            : "#F2EBF5",
          backgroundAlt,
        ],
        opacity: 1,
        height: 100,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div
      ref={scrollableRef}
      className="daily-graph-container-wrapper"
      style={{ height: "60px" }}
    >
      <div
        className={`daily-graph-container ${
          type === "present" ? "present" : ""
        }`}
        style={{ height: "100%" }}
      >
        <ReactApexChart
          options={options}
          series={[{ data: seriesData }]}
          type="rangeBar"
          height={85} // Ensure the chart height is set to 60px
        />
      </div>
    </div>
  );
};

export default DailyGraph;
