import React, { useState } from "react";
import "./styles/navbarMobile.scss";
import { useTheme } from "@mui/material";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { images } from "../../constants";
import { CustomLink } from "../Navbar/Navbar";
import { icons } from "../../constants";
import "../../styles/global.scss";

const NavbarMobile = (props) => {
  const [openPresence, setOpenPresence] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const neutralDarker = theme.palette.neutral.darker;
  const user = {
    company:
      "Galena Lab Galena Lab Galena Lab Galena LabGalena LabGalena LabGalena Lab",
    name: "Marko Markovic Marko Markovic Marko Markovic Marko Markovic Marko Markovic Marko Markovic Marko Markovic Marko Markovic Marko Markovic Marko Markovic Marko Markovic Marko Markovic",
  };

  const handleLogout = async () => {
    setOpenOptions(!openOptions);
  };

  return (
    <div
      className="flex-direction-column justify-center align-start align-stretch width-100 relative navbar-mobile"
      style={{ backgroundColor: backgroundDefault, color: neutralDarker }}
    >
      <div className="flex-direction-row align-center align-stretch justify-space-between">
        <div
          className="flex-direction-row align-center gap-10 height-100"
          style={{ maxWidth: "calc(100% - 30px)" }}
        >
          <img
            src={images.profilePicture}
            className="navbar-mobile-avatar-img"
            height="48px"
            width="48px"
          />
          <div
            className="flex-direction-column gap-5 justify-center align-start navbar-mobile-name"
            style={{ padding: "3px 0" }}
          >
            <p className="Grotesk-500 font-16 ellipsis">{user.name}</p>
            <p className="Grotesk-400 font-16">{user.company}</p>
          </div>
        </div>
        <div className="icon-container relative">
          <MenuIcon
            className={`pointer icon ${
              openOptions ? "icon-hidden" : "icon-visible"
            }`}
            onClick={() => setOpenOptions(!openOptions)}
          />
          <XIcon
            className={`pointer icon ${
              openOptions ? "icon-visible" : "icon-hidden"
            }`}
            onClick={() => setOpenOptions(!openOptions)}
          />
        </div>
      </div>
      <div
        className={`flex-direction-column align-start align-stretch width-100 gap-32 ${
          openOptions ? "navbar-mobile-visible" : "navbar-mobile-invisible"
        }`}
        style={{
          padding: "20px",
          backgroundColor: backgroundDefault,
          color: neutralDarker,
          top: "84px",
          left: "0",
        }}
      >
        <div className="flex-direction-column align-start gap-12 align-stretch">
          <CustomLink
            to="/"
            src={icons.home}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Početna
          </CustomLink>
          <CustomLink
            to="/profile"
            src={icons.profile}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Profil
          </CustomLink>
          <div className="flex-direction-row align-center justify-space-between width-100 relative">
            <CustomLink
              to="/attendance"
              src={icons.time}
              onClick={() => setOpenOptions(!openOptions)}
            >
              Prisustvo
            </CustomLink>
            <img
              src={icons.arrow_down}
              width={34}
              height={23}
              onClick={() => {
                setOpenPresence(!openPresence);
              }}
              className={`pointer presence-arrow ${
                openPresence ? "arrow-hidden" : "arrow-visible"
              }`}
            />
          </div>
          <div
            className={`presence-section ${
              openPresence ? "presence-section-visible" : ""
            }`}
          >
            <CustomLink
              to="/presence"
              onClick={() => setOpenOptions(!openOptions)}
              presenceReview
            >
              Pregled prisustva
            </CustomLink>
          </div>
          <CustomLink
            to="/employees"
            src={icons.employees}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Zaposleni
          </CustomLink>
          <CustomLink
            to="/requests"
            src={icons.requests}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Zahtevi
          </CustomLink>
          <CustomLink
            to="/notifications"
            src={icons.notifications}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Obaveštenja
          </CustomLink>
          <CustomLink
            to="/settings"
            src={icons.settings}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Podešavanja
          </CustomLink>
        </div>
        <div
          className="flex-direction-column align-start gap-14 width-100"
          style={{ padding: "0 15px" }}
        >
          <CustomLink to="/login" src={icons.log_out} onClick={handleLogout}>
            Log out
          </CustomLink>
          <CustomLink
            to="/help"
            src={icons.help}
            onClick={() => setOpenOptions(!openOptions)}
          >
            Pomoć
          </CustomLink>
          <CustomLink
            to="/building"
            src={icons.building}
            onClick={() => setOpenOptions(!openOptions)}
          >
            <div className="flex-direction-row align-center justify-space-between width-100">
              Midmeding Nova
              <img src={icons.arrow_right} width={18} height={16} />
            </div>
          </CustomLink>
          <div style={{ height: "24px" }}></div>
        </div>
      </div>
    </div>
  );
};

export default NavbarMobile;
