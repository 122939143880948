import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { apiSlice } from "./apiSlice";
import userReducer from "./userSlice";
// Import other custom reducers if needed

// Combine reducers
const rootReducer = combineReducers({
  api: apiSlice.reducer, // Add apiSlice.reducer for RTK Query
  user: userReducer, // User slice reducer
  // Add other reducers here if needed
});

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"], // Include "user" to persist the user state
  blacklist: [apiSlice.reducerPath], // Exclude apiSlice state from persistence
};

// Persist the root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store with persisted reducer and middleware
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/REGISTER",
        ],
      },
    }).concat(apiSlice.middleware), // Include apiSlice middleware
});

// Configure persistor
const persistor = persistStore(store);

export { store, persistor };
