export const PresentEmployees = [
  {
    name: "Dimitrije Čabarkapa",
    picture: "",
    activities: [
      { name: "Activity", startTime: 10, endTime: 12, type: "work" },
      { name: "Activity", startTime: 12, endTime: 13, type: "break" },
      { name: "Activity", startTime: 13, endTime: 16, type: "work" },
    ],
    daily: "-08:00",
    current: "+11:00",
  },
  {
    name: "Dimitrije Čabarkapa",
    picture: "",
    activities: [
      { name: "Activity", startTime: 9.5, endTime: 12, type: "work" },
      { name: "Activity", startTime: 12, endTime: 13, type: "break" },
      { name: "Activity", startTime: 13, endTime: 16, type: "work" },
    ],
    daily: "-08:00",
    current: "+11:00",
  },
  {
    name: "Dimitrije Čabarkapa",
    picture: "",
    activities: [
      { name: "Activity", startTime: 10, endTime: 12, type: "work" },
      { name: "Activity", startTime: 12, endTime: 13, type: "break" },
      { name: "Activity", startTime: 13, endTime: 16, type: "work" },
    ],
    daily: "-08:00",
    current: "+11:00",
  },
  {
    name: "Dimitrije ČabarkapaDimitrije Čabarkapa",
    picture: "",
    activities: [
      { name: "Activity", startTime: 7, endTime: 8, type: "work" },
      { name: "Activity", startTime: 8, endTime: 8.2, type: "break" },
      { name: "Activity", startTime: 8.2, endTime: 9.5, type: "work" },
      { name: "Activity", startTime: 9.5, endTime: 10.5, type: "break" },
      { name: "Activity", startTime: 10.5, endTime: 13, type: "work" },
    ],
    daily: "-08:00",
    current: "+11:00",
  },
  {
    name: "Dimitrije ČabarkapaDimitrije Čabarkapa",
    picture: "",
    activities: [
      { name: "Activity", startTime: 7, endTime: 8, type: "work" },
      { name: "Activity", startTime: 8, endTime: 8.2, type: "break" },
      { name: "Activity", startTime: 8.2, endTime: 9.5, type: "work" },
      { name: "Activity", startTime: 9.5, endTime: 10.5, type: "break" },
      { name: "Activity", startTime: 10.5, endTime: 13, type: "work" },
    ],
    daily: "-08:00",
    current: "+11:00",
  },
  {
    name: "Dimitrije ČabarkapaDimitrije Čabarkapa",
    picture: "",
    activities: [
      { name: "Activity", startTime: 7, endTime: 8, type: "work" },
      { name: "Activity", startTime: 8, endTime: 8.2, type: "break" },
      { name: "Activity", startTime: 8.2, endTime: 9.5, type: "work" },
      { name: "Activity", startTime: 9.5, endTime: 10.5, type: "break" },
      { name: "Activity", startTime: 10.5, endTime: 13, type: "work" },
    ],
    daily: "-08:00",
    current: "+11:00",
  },
  {
    name: "Dimitrije ČabarkapaDimitrije Čabarkapa",
    picture: "",
    activities: [
      { name: "Activity", startTime: 7, endTime: 8, type: "work" },
      { name: "Activity", startTime: 8, endTime: 8.2, type: "break" },
      { name: "Activity", startTime: 8.2, endTime: 9.5, type: "work" },
      { name: "Activity", startTime: 9.5, endTime: 10.5, type: "break" },
      { name: "Activity", startTime: 10.5, endTime: 13, type: "work" },
    ],
    daily: "-08:00",
    current: "+11:00",
  },
];
export const checkedOutEmployees = [
  {
    name: "Dragan Čabarkapa",
    picture: "",
    activities: [
      { name: "Activity", startTime: 10, endTime: 12, type: "work" },
      { name: "Activity", startTime: 12, endTime: 13, type: "break" },
      { name: "Activity", startTime: 13, endTime: 16, type: "work" },
    ],
    checkIn: "08:00",
    checkOut: "11:00",
  },
  {
    name: "Dimitrije Čabarkapa",
    picture: "",
    activities: [
      { name: "Activity", startTime: 7, endTime: 8, type: "work" },
      { name: "Activity", startTime: 8, endTime: 8.2, type: "break" },
      { name: "Activity", startTime: 8.2, endTime: 9.5, type: "work" },
      { name: "Activity", startTime: 9.5, endTime: 10.5, type: "break" },
      { name: "Activity", startTime: 10.5, endTime: 13, type: "work" },
    ],
    checkIn: "08:00",
    checkOut: "11:00",
  },
  {
    name: "Dimitrije Čabarkapa",
    picture: "",
    activities: [
      { name: "Activity", startTime: 10, endTime: 12, type: "work" },
      { name: "Activity", startTime: 12, endTime: 13, type: "break" },
      { name: "Activity", startTime: 13, endTime: 16, type: "work" },
    ],
    checkIn: "08:00",
    checkOut: "11:00",
  },
  {
    name: "Dimitrije ČabarkapaDimitrije Čabarkapa",
    picture: "",
    activities: [
      { name: "Activity", startTime: 9.5, endTime: 12, type: "work" },
      { name: "Activity", startTime: 12, endTime: 13, type: "break" },
      { name: "Activity", startTime: 13, endTime: 16, type: "work" },
    ],
    checkIn: "08:00",
    checkOut: "11:00",
  },
];
export const holidaysEmployees = [
  {
    name: "Petar Čabarkapa",
    picture: "",
    activities: [{ name: "Activity", startTime: 8, endTime: 17, type: "work" }],
    start: "22.04.24",
    end: "06.05.24",
  },
  {
    name: "Dimitrije Čabarkapa",
    picture: "",
    activities: [{ name: "Activity", startTime: 8, endTime: 17, type: "work" }],
    start: "22.04.24",
    end: "06.05.24",
  },
  {
    name: "Dimitrije Čabarkapa",
    picture: "",
    activities: [{ name: "Activity", startTime: 8, endTime: 17, type: "work" }],
    start: "22.04.24",
    end: "06.05.24",
  },
  {
    name: "Dimitrije ČabarkapaDimitrije Čabarkapa",
    picture: "",
    activities: [{ name: "Activity", startTime: 8, endTime: 17, type: "work" }],
    start: "22.04.24",
    end: "06.05.24",
  },
];
export const unregisteredEmployees = [
  {
    name: "Petar Čabarkapa",
    picture: "",
    activities: [{ name: "Activity", startTime: 8, endTime: 17, type: "work" }],
    start: "22.04.24",
    startTime: "08:00",
    end: "06.05.24",
    endTime: "18:00",
  },
  {
    name: "Dimitrije Čabarkapa",
    picture: "",
    activities: [{ name: "Activity", startTime: 8, endTime: 17, type: "work" }],
    start: "22.04.24",
    startTime: "08:00",
    end: "06.05.24",
    endTime: "18:00",
  },
  {
    name: "Dimitrije Čabarkapa",
    picture: "",
    activities: [{ name: "Activity", startTime: 8, endTime: 17, type: "work" }],
    start: "22.04.24",
    startTime: "08:00",
    end: "06.05.24",
    endTime: "18:00",
  },
  {
    name: "Dimitrije ČabarkapaDimitrije Čabarkapa",
    picture: "",
    activities: [{ name: "Activity", startTime: 8, endTime: 17, type: "work" }],
    start: "22.04.24",
    startTime: "08:00",
    end: "06.05.24",
    endTime: "18:00",
  },
];
