import React, { useState, useEffect } from "react";
import "../styles/homepage.scss";
import ReactApexChart from "react-apexcharts";
import "chart.js/auto";
import { useTheme } from "@mui/material";

const timeLines = {
  dolazak: "07:32",
  odlazak: "15:34",
  total: "08:02",
};

const DailyGraph = ({ title, color }) => {
  const activities = ActivityData;

  const typeColors = {
    work: "#883CEF",
    break: "#5707B1",
  };

  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia("(max-width: 600px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");

    const handleMediaQueryChange = (e) => {
      setIsSmallScreen(e.matches);
    };

    mediaQuery.addListener(handleMediaQueryChange);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  // Convert activities to ApexCharts series data
  const seriesData = activities.map((activity) => ({
    x: activity.name,
    y: [activity.startTime, activity.endTime],
    fillColor: typeColors[activity.type],
  }));

  // Use theme
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const backgroundAlt = theme.palette.background.alt;

  // ApexCharts Configuration
  const options = {
    series: [
      {
        data: seriesData,
      },
    ],
    chart: {
      type: "rangeBar",
      background: backgroundAlt, // Set chart background color
      events: {
        click: function (event, chartContext, config) {
          return false;
        },
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "75%",
        borderRadius: 12,
        rangeBarGroupRows: true,
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "numeric",
      tickAmount: isSmallScreen ? 12 : 24, // Change tick amount based on screen size
      labels: {
        style: {
          fontFamily: "Outfit",
        },
        formatter: (val) => `${val}`,
      },
      min: 0,
      max: 24,
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      row: {
        colors: ["#F2EBF5", backgroundAlt],
        opacity: 1,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className="daily-graph-container-wrapper">
      <div className="daily-graph-container">
        <ReactApexChart
          options={options}
          series={[{ data: seriesData }]}
          type="rangeBar"
          height={120}
          className="aca"
        />
      </div>
    </div>
  );
};

export default DailyGraph;

const ActivityData = [
  { name: "Activity", startTime: 6, endTime: 12, type: "work" },
  { name: "Activity", startTime: 12, endTime: 13, type: "break" },
  { name: "Activity", startTime: 13, endTime: 16, type: "work" },
  { name: "Activity", startTime: 16, endTime: 18, type: "break" },
];
