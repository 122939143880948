import React from "react";
import { useTheme } from "@mui/material";

const EmploymentTimeline = () => {
  const employments = [
    {
      company:
        "Midmeding Nova Midmeding Nova Midmeding Nova Midmeding Nova Midmeding Nova",
      city: "Beograd Beograd BeogradBeograd Beograd",
      position:
        "HR Team Lead HR Team Lead HR Team Lead HR Team Lead HR Team Lead",
      start_date: "01-2024 01-2024 01-2024",
      end_date: "",
    },
    {
      company: "Midmeding Nova",
      city: "Beograd",
      position: "HR Consultant",
      start_date: "07-2022",
      end_date: "12-2023 01-2024 01-2024",
    },
    {
      company: "Midmeding Nova",
      city: "Beograd",
      position: "Junior HR Consultant",
      start_date: "07-2021",
      end_date: "07-2022",
    },
    {
      company: "Midmeding Nova",
      city: "Beograd",
      position: "HR Academy",
      start_date: "04-2021",
      end_date: "07-2021",
    },
  ];

  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const neutralMedium = theme.palette.neutral.medium;
  const neutralDarker = theme.palette.neutral.darker;

  return (
    <div
      className="flex-direction-column gap-24 align-start shadows height-100 width-100 employment-timeline "
      style={{ backgroundColor: backgroundDefault }}
    >
      <p
        className="Grotesk-500 font-16 employment-timeline-title"
        style={{ color: neutralMedium }}
      >
        Istorija zaposlenja
      </p>
      <div className="flex-direction-row align-start gap-17 align-stretch">
        <div className="flex-direction-column align-center justify-center flex-1-0-0 gap-24">
          {employments.map((job, index) => (
            <div className="width-100 animated" key={index}>
              <div className="flex-direction-row justify-space-between align-center align-stretch employment-timeline-row">
                <div className="flex-direction-column justify-center align-start gap-5 flex-1-0-0">
                  <p
                    className="Grotesk-500 font-16 capitalize employment-timeline-position"
                    style={{ color: neutralDarker }}
                  >
                    {job.position}
                  </p>
                  <div className="flex-direction-row align-start gap-5 width-100">
                    <p
                      className="Grotesk-400 font-16 capitalize"
                      style={{ color: neutralMedium }}
                    >
                      {job.company},
                    </p>
                    <p
                      className="Grotesk-400 font-16 capitalize"
                      style={{ color: neutralDarker }}
                    >
                      {job.city}
                    </p>
                  </div>
                </div>
                <div
                  className="flex-direction-row gap-4 align-center employment-timeline-dates"
                  style={{ width: "152px" }}
                >
                  <p
                    className="Grotesk-400 font-16 capitalize"
                    style={{ color: neutralDarker }}
                  >
                    {job.start_date}
                  </p>
                  <span>-</span>
                  <p
                    className="Grotesk-400 font-16  capitalize"
                    style={{ color: neutralDarker }}
                  >
                    {job.end_date === "" ? "Trenutno" : job.end_date}
                  </p>
                </div>
              </div>
              <div
                className="width-100 "
                style={{
                  height: "1px",
                  backgroundColor: "#818181",
                  opacity: ".11",
                }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmploymentTimeline;
