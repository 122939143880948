import React from "react";
import "../../../styles/attendancePage.scss";
import "../../../../../styles/global.scss";
import EmployeesRow from "../../../../EmployeesPage/components/EmployeesRow";
import WorkHoursTrackerRow from "./WorkHoursTrackerRow";

const EmployeePerson = () => {
  return (
    <div className="flex-direction-column gap-48 width-100 employee-person">
      <EmployeesRow employee={employeePerson} />
      <div className="flex-direction-column gap-24 justify-center align-start align-stretch">
        {data.map((employee, index) => (
          <WorkHoursTrackerRow employed={employee} key={index} employeePerson />
        ))}
      </div>
    </div>
  );
};

export default EmployeePerson;
const employeePerson = {
  name: "Marko Gvozdenović",
  email: "markogvozdenovic@gmail.com",
  id: "2244756469",
  position: "Frontend developer",
  jobSector: "Odeljak Beograd",
  status: "Određeno 6",
};

const data = [
  {
    date: "1.novembar 2023",
    daily: "-08:00",
    current: "+11:00",
    activities: [
      { name: "Activity", startTime: 10, endTime: 12, type: "work" },
      { name: "Activity", startTime: 12, endTime: 13, type: "break" },
      { name: "Activity", startTime: 13, endTime: 16, type: "work" },
    ],
  },
  {
    date: "1.novembar 2023",
    daily: "-08:00",
    current: "+11:00",
    activities: [
      { name: "Activity", startTime: 10, endTime: 12, type: "work" },
      { name: "Activity", startTime: 12, endTime: 13, type: "break" },
      { name: "Activity", startTime: 13, endTime: 16, type: "work" },
    ],
  },
];
