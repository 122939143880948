import React from "react";
import "./styles/profilePage.scss";
import "../../styles/global.scss";
import PersonalInformation from "./components/PersonalInformation";
import EmploymentTimeline from "./components/EmploymentTimeline";
import ProfileDetails from "./components/ProfileDetails";

const ProfilePage = () => {
  return (
    <div className="flex-direction-column gap-24 align-start width-100 height-100 profile-page">
      <div className="flex-direction-row gap-24 align-start width-100 personal-information-employment ">
        <PersonalInformation />
        <EmploymentTimeline />
      </div>
      <ProfileDetails />
    </div>
  );
};

export default ProfilePage;
