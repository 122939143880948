import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Switch,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import "./styles/app.scss";
import Navbar from "./components/Navbar/Navbar";
import HomePage from "./pages/HomePage/HomePage";
import HomePageAdmin from "./pages/HomePage/HomePageAdmin";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import AttendancePage from "./pages/AttendancePage/AttendancePage";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./styles/theme";
import NavbarMobile from "./components/NavbarMobile/NavbarMobile";
import AddEmployeePage from "./pages/AddEmployeePage/AddEmployeePage";
import EmployeesPage from "./pages/EmployeesPage/EmployeesPage";

function App() {
  const mode = "light";
  const type = "user";
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return (
    <div className="app-container">
      <ThemeProvider theme={theme}>
        <Navbar />
        <div className="content">
          <NavbarMobile />
          <Routes>
            <Route
              path="/"
              element={type === "user" ? <HomePage /> : <HomePageAdmin />}
            />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/attendance" element={<AttendancePage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/employees" element={<EmployeesPage />} />
            <Route path="/employees/add" element={<AddEmployeePage />} />
          </Routes>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
