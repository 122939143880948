import React, { useState } from "react";
import ButtonApp from "../../../../../components/ButtonsApp/ButtonRegular";
import { icons } from "../../../../../constants";
import TimeTable from "../../../../HomePage/components/TimeTable";
import "../../../styles/attendancePage.scss";
import AddActionModal from "./AddActionModal";
import ApexChart from "./ApexChart";

const WorkHoursEditor = ({ date }) => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [rowId, setRowId] = useState();

  const selectedRow = rowId ? list.find((item) => item._id === rowId) : null;
  return (
    <div className="flex-direction-row align-start gap-12 align-stretch workHours-editor">
      {addModal ? (
        <AddActionModal handleCloseClick={() => setAddModal(false)} />
      ) : editModal ? (
        <AddActionModal
          handleCloseClick={() => setEditModal(false)}
          data={selectedRow}
          editing
          id={rowId}
        />
      ) : (
        <div className="flex-direction-column align-start gap-12 workHours-editor-gauge-buttons">
          <div
            className="flex-direction-column align-center align-stretch"
            style={{
              borderRadius: "24px",
              boxShadow: "0px 0px 8px -2px rgba(31, 32, 37, 0.24)",
              height: "263px",
            }}
          >
            <ApexChart />
          </div>
          <div className="flex-direction-row align-start gap-12 align-stretch workHours-editor-buttons">
            <ButtonApp
              title="Dodaj akciju"
              icon={icons.add}
              className="workHours-editor-btn"
              onButtonClick={() => setAddModal(true)}
            />
            <ButtonApp
              title="Izmeni akciju"
              icon={icons.pencil}
              className="workHours-editor-btn"
              onButtonClick={() => rowId && setEditModal(true)}
            />
          </div>
        </div>
      )}

      <div className="flex-direction-column gap-24 workHours-editor-data">
        <div className="flex-direction*column align-start gap-12 align-stretch shadows-0-0-8 workHours-editor-dataTable">
          <TimeTable
            onRowClick={(value) => setRowId(value)}
            clickedRow={rowId}
            list={list}
          />
        </div>
        <ButtonApp
          title="Vidi vise"
          icon={icons.arrow_right}
          className="see-more"
        />
      </div>
    </div>
  );
};

export default WorkHoursEditor;

// ova lista se dohvata na osnovu datuma iz propsa
const list = [
  {
    _id: "111",
    type: "Dolazak",
    time: "11:15",
    address: "112, Vracar",
    date: "2024-12-03",
  },
  {
    _id: "222",
    type: "Odlazak",
    time: "11:05",
    address: "112, Vracar Vracar Vracar Vracar VracarVracarVracarVracar",
    date: "2024-12-03",
  },
  {
    _id: "333",
    type: "Pauza",
    time: "11:05",
    address: "112, Vracar",
    date: "2024-11-03",
  },
  {
    _id: "444",
    type: "Rad od kuce Rad od kuce Rad od kuce Rad od kuce",
    time: "11:05",
    address: "112, Vracar",
    date: "2024-11-03",
  },
  {
    _id: "555",
    type: "Privatan izlazak",
    time: "11:05",
    address: "112, Vracar",
    date: "2024-10-03",
  },
  {
    _id: "666",
    type: "Sluzbeni izlazak",
    time: "11:05",
    address: "112, Vracar",
    date: "2024-10-03",
  },
  {
    _id: "777",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
    date: "2024-12-03",
  },
  {
    _id: "888",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
    date: "2024-12-03",
  },
  {
    _id: "999",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
    date: "2024-12-03",
  },
  {
    _id: "1111",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
    date: "2024-12-03",
  },
  {
    _id: "2222",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
    date: "2024-12-03",
  },
  {
    _id: "3333",
    type: "Dolazak",
    time: "11:05",
    address: "112, Vracar",
    date: "2024-12-03",
  },
];
