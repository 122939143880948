import React, { useState } from "react";
import DailyGraph from "../../../../../components/DailyGraph/DailyGraph";
import { images } from "../../../../../constants";
import { useTheme } from "@mui/material";
import WorkHoursEditor from "./WorkHoursEditor";

const WorkHoursTrackerRow = ({
  employed,
  status,
  date,
  employeePerson,
  activeButton,
}) => {
  const [openHoursEditor, setOpenHoursEditor] = useState(false);
  const theme = useTheme();
  const backgroundAlt = theme.palette.background.alt;
  const neutralMedium = theme.palette.neutral.medium;

  return (
    <>
      <div
        className={`flex-direction-row align-center align-stretch pointer ${
          status === "unregistered" ? "unregistered" : ""
        } workHours-tracker-row`}
        style={{ backgroundColor: backgroundAlt }}
        onClick={() =>
          activeButton === "day" && setOpenHoursEditor(!openHoursEditor)
        }
      >
        <div className="flex-direction-row align-center gap-12">
          <div className="flex-direction-row align-center justify-center workHours-tracker-row-picture">
            {employeePerson ? (
              <p className="Grotesk-500 font-14 workHours-tracker-row-name">
                {employed.date}
              </p>
            ) : (
              <img src={images.profilePicture} width={34} height={34} />
            )}
          </div>
          <p
            className="Grotesk-500 font-14 workHours-tracker-row-name"
            style={{ lineHeight: "18px", height: "18px", color: neutralMedium }}
          >
            {employed.name}
          </p>
        </div>
        <DailyGraph item={employed.activities} status={status} />

        <div className="flex-direction-row align-center gap-20 workHours-tracker-row-hours">
          <div className="flex-direction-column justify-center  gap-5 align-stretch">
            <p
              className="Grotesk-500 font-12"
              style={{
                lineHeight: "16px",
                maxWidth: "30px",
                whiteSpace: "wrap",
                overflow: "visible",
                color: neutralMedium,
              }}
            >
              {status === "unregistered"
                ? "Poslednja prijava"
                : status === "checkOut"
                ? "Prijava"
                : status === "holiday"
                ? "Pocetak"
                : "Dnevni"}
            </p>
            <p
              className="Grotesk-400 font-12"
              style={{ lineHeight: "16px", color: neutralMedium }}
            >
              {status === "unregistered"
                ? employed.start
                : status === "checkOut"
                ? employed.checkIn
                : status === "holiday"
                ? employed.start
                : employed.daily}
            </p>
            {status === "unregistered" && (
              <p
                className="Grotesk-400 font-12"
                style={{ lineHeight: "16px", color: neutralMedium }}
              >
                {employed.startTime}
              </p>
            )}
          </div>
          <div className="flex-direction-column justify-center  gap-5 align-stretch">
            <p
              className="Grotesk-500 font-12"
              style={{
                lineHeight: "16px",
                maxWidth: "30px",
                whiteSpace: "wrap",
                overflow: "visible",
                color: neutralMedium,
              }}
            >
              {status === "unregistered"
                ? "Poslednja odjava"
                : status === "checkOut"
                ? "Odjava"
                : status === "holiday"
                ? "Kraj"
                : "Tekući"}
            </p>
            <p
              className="Grotesk-400 font-12"
              style={{ lineHeight: "16px", color: neutralMedium }}
            >
              {status === "unregistered"
                ? employed.end
                : status === "checkOut"
                ? employed.checkOut
                : status === "holiday"
                ? employed.end
                : employed.current}
            </p>
            {status === "unregistered" && (
              <p
                className="Grotesk-400 font-12"
                style={{ lineHeight: "16px", color: neutralMedium }}
              >
                {employed.endTime}
              </p>
            )}
          </div>
        </div>
      </div>

      {openHoursEditor && <WorkHoursEditor date={date} />}
    </>
  );
};
export default WorkHoursTrackerRow;
