import React from "react";
import {
  PresentEmployees,
  checkedOutEmployees,
  holidaysEmployees,
  unregisteredEmployees,
} from "../../../const";
import WorkHoursTrackerRow from "./WorkHoursTrackerRow";
import "../../../styles/attendancePage.scss";

const WorkHoursTracker = (props) => {
  const arrayForDisplay =
    props.status === "unregistered"
      ? unregisteredEmployees
      : props.status === "checkOut"
      ? checkedOutEmployees
      : props.status === "holiday"
      ? holidaysEmployees
      : PresentEmployees;
  return (
    <div className="flex-direction-column align-center gap-12 align-stretch workHours-tracker">
      {arrayForDisplay.map((employed, index) => (
        <>
          <WorkHoursTrackerRow
            employed={employed}
            key={index}
            status={props.status}
            date={props.date}
            activeButton={props.activeButton}
          />
        </>
      ))}
    </div>
  );
};

export default WorkHoursTracker;
