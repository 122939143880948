import React, { useState } from "react";
import "../styles/homepage.scss";
import { icons } from "../../../constants";
import { useTheme } from "@mui/material";

const timeLines = {
  dolazak: "07:32",
  odlazak: "15:31",
  pauza: "12:31",
  total: "08:02",
};

const HeaderTimeLine = () => {
  const theme = useTheme();
  const backgroundDefault = theme.palette.background.default;
  const neutralMedium = theme.palette.neutral.medium;
  return (
    <div
      className="header-component"
      style={{ backgroundColor: backgroundDefault, borderRadius: "24px" }}
    >
      <div className="header-items">
        <img src={icons.in_icon} />
        <div className="header-text">
          <p
            className="Grotesk-500 home-header-term"
            style={{ color: neutralMedium }}
          >
            Dolazak
          </p>
          <p className="Grotesk-400 home-header-value">{timeLines.dolazak}</p>
        </div>
      </div>
      <div className="header-items">
        <img src={icons.out} />
        <div className="header-text">
          <p
            className="Grotesk-500 home-header-term"
            style={{ color: neutralMedium }}
          >
            Odlazak
          </p>
          <p className="Grotesk-400 home-header-value">{timeLines.odlazak}</p>
        </div>
      </div>
      <div className="header-items">
        <img src={icons.pause} />
        <div className="header-text">
          <p
            className="Grotesk-500 home-header-term"
            style={{ color: neutralMedium }}
          >
            Puzua
          </p>
          <p className="Grotesk-400 home-header-value">{timeLines.pauza}</p>
        </div>
      </div>
      <div className="header-items">
        <img src={icons.clockBlack} fill="red" />
        <div className="header-text">
          <p
            className="Grotesk-500 home-header-term"
            style={{ color: neutralMedium }}
          >
            Ukupno
          </p>
          <p className="Grotesk-400 home-header-value">{timeLines.total}h</p>
        </div>
      </div>
    </div>
  );
};
export default HeaderTimeLine;
